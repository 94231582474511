<template>
    <div
        v-bind="extendedWrapperAttrs"
        class="dn-textarea"
        :class="{
            'dn-textarea--has-floating-label': !!label
        }"
        @click="onClickWrapper"
    >
        <div class="dn-textarea__field">
            <div class="dn-textarea__field-body">
                <textarea
                    ref="input"
                    :value="modelValue"
                    v-bind="extendedInputAttrs"
                    @focus="onFocusInput"
                    @blur="onBlurInput"
                    @input="onInput"
                    @change="onChange"
                />

                <label
                    v-if="label"
                    class="dn-textarea__label"
                >{{ label }}</label>
            </div>

            <transition name="dn-textarea-fade">
                <div
                    v-if="showClearButton || hasStatus"
                    class="dn-textarea__field-aside"
                >
                    <transition name="dn-textarea-fade">
                        <button
                            v-if="showClearButton"
                            type="button"
                            class="dn-textarea__clear"
                            title="Reset"
                            tabindex="-1"
                            @click="onClear"
                        >
                            <slot name="clear">⨉</slot>
                        </button>
                    </transition>

                    <transition name="dn-textarea-fade">
                        <div
                            v-if="hasStatus"
                            v-bind="slotProps"
                            class="dn-textarea__status"
                        >
                            <slot name="status" />
                        </div>
                    </transition>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
'use strict';

import InputMixin from './mixins/input';

export default {
    name: 'DnTextarea',

    mixins: [InputMixin],

    inheritAttrs: false,

    props: {
        autoResize: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        extendedWrapperAttrs() {
            return {
                ...this.defaultWrapperAttrs,
                ...this.wrapperAttrs,
            };
        },
        extendedInputAttrs() {
            return {
                ...this.defaultInputAttrs,
                ...this.$attrs,
            };
        }
    },

    watch: {
        modelValue() {
            this.resizeTextarea();
        }
    },

    mounted() {
        this.resizeTextarea();
    },

    methods: {
        resizeTextarea() {
            if (!this.autoResize) {
                return false;
            }

            this.$refs.input.style.height = 'auto';
            this.$refs.input.style.height = `${this.$refs.input.scrollHeight}px`;
        }
    }
};

</script>

<style lang="less" src="./textarea.less"></style>
